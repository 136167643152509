<template>
  <div>
    <UpcomingInvoicesTreatmentList
      :invoiceId="invoiceId"
      @saveTreatmentDetail="saveTreatmentDetail"
      @saveUpdatedTreatmentDetails="saveUpdatedTreatmentDetails"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UpcomingInvoicesTreatmentList from "../../components/invoice/manageTreatment";

export default {
  components: {
    UpcomingInvoicesTreatmentList
  },
  data() {
    return {
      invoiceId: ''
    };
  },
  methods: {
    ...mapActions("general", [
      "saveUpcomingInvoiceTreatmentDetail",
      "saveUpdatedTreatmentDetail",
    ]),
    saveTreatmentDetail(data) {
      let self=this;
      self.$vs.loading();
      this.saveUpcomingInvoiceTreatmentDetail(data).then(res => {
        self.$vs.notify({
          title: "Upcoming Invoice Details",
          text: "Invoice treatment details saved successfully",
          color: "success",
        });
        self.$vs.loading.close();
        this.$router.push({ name: 'pastInvoiceList'})
      }).catch(err => {
        self.$vs.notify({
          title: err.data.title,
          text: err.data.message,
          color: 'danger'
        });
        self.$vs.loading.close();
      });
    },
    saveUpdatedTreatmentDetails(data) {
      let self=this;
      self.$vs.loading();
      this.saveUpdatedTreatmentDetail(data)
        .then(res => {
          self.$vs.notify({
            title: "Success",
            text: "Updated treatment details saved successfully",
            color: "success",
          });
          self.$vs.loading.close();
        }).catch(err => {
          self.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: 'danger'
          });
          self.$vs.loading.close();
        });
    },
  },
  async created() {
    this.invoiceId = this.$route.params.id;
  }
};
</script>
