<template>
  <div id="div-with-loading" class="vs-con-loading__container">
    <vx-card style="padding-left: 10px">
      <div>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full mt-5 mb-5">
              <vs-row
                vs-align="flex-start"
                vs-type="flex"
                vs-justify="left"
                vs-w="12"
              >
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <div class="w-full">
                    <p>
                      <strong>
                        Invoice # {{ invoiceNumber }} <br />
                        {{ clinicName }} <br />
                        {{
                          moment(month).subtract(1, "months").format("MMMM")
                        }}
                        {{ year }} <br />
                        Status:
                        <span
                          v-if="voidEntirePayment === true"
                          style="color: red"
                          >Cancelled</span
                        >
                        <span v-else-if="voidEntirePayment === false"
                          >Scheduled for 07 {{ moment(month).format("MMMM") }}
                          {{ year }}</span
                        >
                      </strong>
                    </p>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full mt-5 mb-5" align="right">
              <vs-dropdown vs-trigger-click>
                <vs-button type="border"
                  >Invoice Options
                  <vs-icon class="" icon="expand_more"></vs-icon>
                </vs-button>
                <vs-dropdown-menu v-if="treatmentData.length > 0">
                  <ul style="min-width: 13rem">
                    <li
                      v-if="voidEntirePayment === true"
                      class="
                        flex
                        py-2
                        px-4
                        cursor-pointer
                        hover:bg-primary hover:text-white
                      "
                      @click="invoiceOptionHandler()"
                    >
                      <span class="ml-2">Activate Payment</span>
                    </li>
                    <li
                      v-else-if="voidEntirePayment === false"
                      class="
                        flex
                        py-2
                        px-4
                        cursor-pointer
                        hover:bg-primary hover:text-white
                      "
                      @click="invoiceOptionHandler()"
                    >
                      <span class="ml-2">Cancel Payment</span>
                    </li>
                  </ul>
                </vs-dropdown-menu>
              </vs-dropdown>
              <!--            <vs-button
                            align="right"
                            icon-pack="feather"
                            icon="icon-arrow">
                            Send Treatment List
                          </vs-button>-->
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="treatmentData"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
          @selected="checkVoidPayment"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse flex-grow justify-between"
          >
            <div style="float: left">
              <vs-select
                placeholder="10"
                vs-multiple
                autocomplete
                v-model="dataTableParams.limit"
                class="mr-6"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in limitOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
          </div>

          <template slot="thead">
            <vs-th sort-key="createdAt">Date</vs-th>
            <vs-th>Clinic Name</vs-th>
            <vs-th>Nurse</vs-th>
            <vs-th>Patient</vs-th>
            <vs-th>Doctor</vs-th>
            <vs-th>Treatment</vs-th>
            <vs-th>Reused</vs-th>
            <vs-th>Price</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              @click="checkVoidPayment"
            >
              <vs-td :data="data[indextr].appointmentCreatedAt">
                {{
                  moment(data[indextr].appointmentCreatedAt).format(
                    "DD/MM/YYYY"
                  )
                }}
              </vs-td>
              <vs-td :data="data[indextr].clinicDetail.name">
                <div v-if="data[indextr].clinicDetail.name">
                  {{ data[indextr].clinicDetail.name | capitalize }}
                </div>
                <div v-else>N/A</div>
              </vs-td>
              <vs-td>
                <div v-if="data[indextr].clinicDetail.name">
                  {{ data[indextr].nurseDetail.name | capitalize }}
                </div>
                <div v-else>N/A</div>
              </vs-td>
              <vs-td>
                <div v-if="data[indextr].clinicDetail.name">
                  {{ data[indextr].patientDetail.name | capitalize }}
                </div>
                <div v-else>N/A</div>
              </vs-td>
              <vs-td>
                <div v-if="data[indextr].clinicDetail.name">
                  {{ data[indextr].doctorDetail.name | capitalize }}
                </div>
                <div v-else>N/A</div>
              </vs-td>
              <vs-td>
                <div v-for="item in data[indextr].treatment" v-bind:key="item">
                  {{ item }}
                </div>
              </vs-td>
              <vs-td>
                {{ data[indextr].isScripted ? 'Yes' : 'No' }}
              </vs-td>
              <vs-td>
                ${{ data[indextr].price }}
              </vs-td>
              <vs-td class="text-center">
                <vs-dropdown
                  closable
                  vs-trigger-click
                  :disabled="voidEntirePayment === true"
                >
                  <span>
                    <feather-icon icon="EditIcon" size="1" />
                  </span>
                  <vs-dropdown-menu class="vx-navbar-dropdown">
                    <ul style="min-width: 15rem">
                      <li class="flex py-2 px-4">
                        <span class="ml-2">Treatment Price</span>
                      </li>
                      <li
                        class="
                          flex
                          py-2
                          px-4
                          cursor-pointer
                          hover:bg-primary hover:text-white
                        "
                        @click="invoicingHandler(indextr, 0)"
                      >
                        <span class="ml-2 flex">
                          $0
                          <feather-icon v-show="data[indextr].price === 0" icon="CheckIcon" size="1" class="selected-price-icon" />
                        </span>
                      </li>
                      <li
                        class="
                          flex
                          py-2
                          px-4
                          cursor-pointer
                          hover:bg-primary hover:text-white
                        "
                        @click="invoicingHandler(indextr, 20)"
                      >
                        <span class="ml-2 flex">
                          $20
                          <feather-icon v-show="data[indextr].price === 20" icon="CheckIcon" size="1" class="selected-price-icon" />
                        </span>
                      </li>
                      <li
                        class="
                          flex
                          py-2
                          px-4
                          cursor-pointer
                          hover:bg-primary hover:text-white
                        "
                        @click="invoicingHandler(indextr, 25)"
                      >
                        <span class="ml-2 flex">
                          $25
                          <feather-icon v-show="data[indextr].price === 25" icon="CheckIcon" size="1" class="selected-price-icon" />
                        </span>
                      </li>
                    </ul>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="mt-2">
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>

      <vs-row>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full mt-5" v-if="treatmentData.length > 0">
            <vs-button
              :disabled="voidEntirePayment == true"
              @click="showSaveUpdatedTreatmentsConfirmation"
              icon-pack="feather"
              class="mr-2"
              >Save</vs-button
            >

            <vs-button
              :disabled="voidEntirePayment == true"
              @click="sendUpdatedPdf"
              icon-pack="feather"
              class="mr-2"
              >Send</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <!--    Void entire payment confirmation popup-->
    <vs-popup
      title="Cancel this payment?"
      :active.sync="voidEntirePaymentPopUp"
      style="padding: 40px"
    >
      <div v-if="voidEntirePayment === false">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full m-2">
              <p>
                Cancelling this payment will stop the scheduled payment to be
                made on 07 {{ moment(month).add(1, "months").format("MMMM") }}
                {{ year }}.
                <strong
                  >All Doctors will still be paid for this treatment.</strong
                >
                You can change this status anytime before the payments for
                {{ moment(month).add(1, "months").format("MMMM") }}
                {{ year }} are triggered.
              </p>
              <br />
              <p>Do you want to continue?</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-2">
              <vs-button @click="cancelPopUpHandler" icon-pack="feather"
                >No, keep payment</vs-button
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-2" align="right">
              <vs-button
                @click="cancelPaymentHandler"
                icon-pack="feather"
                type="border"
                color="danger"
                >Yes, cancel payment</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </div>
      <div v-else-if="voidEntirePayment === true">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full m-2">
              <p>
                The payment for the entire clinic will be activated for 07
                {{ moment(month).add(1, "months").format("MMMM") }} {{ year }}.
                You can change this status anytime before the payments for
                {{ moment(month).add(1, "months").format("MMMM") }}
                {{ year }} are triggered.
              </p>
              <br />
              <p>Do you want to continue?</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-2">
              <vs-button
                @click="cancelPopUpHandler"
                icon-pack="feather"
                type="border"
                color="danger"
                >No, keep cancelled</vs-button
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-2" align="right">
              <vs-button @click="activatePaymentHandler" icon-pack="feather"
                >Yes, activate payment</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>

    <!-- Confirmation before save and send -->
    <vs-popup
      title="Confirmation for save and send"
      :active.sync="saveSendConfirmationPopUp"
      style="padding: 20px"
    >
      <div>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full m-5">
              <p>
                Are you sure you want to save and send this updated treatment
                list?
              </p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5" align="right">
              <vs-button @click="saveDetails" icon-pack="feather"
                >Save & Send</vs-button
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5" align="left">
              <vs-button
                @click="saveSendConfirmationPopUp = false"
                icon-pack="feather"
                type="border"
                color="danger"
                >Cancel</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>

    <!-- Confirmation before save updated treatments -->
    <vs-popup
      title="Confirmation for saving updated treatments"
      :active.sync="saveUpdatedTreatmentsConfirmationPopUp"
      style="padding: 20px"
    >
      <div>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full m-5">
              <p>Are you sure you want to save this updated treatment list?</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5" align="right">
              <vs-button
                @click="saveUpdatedTreatmentDetails"
                icon-pack="feather"
                >Save</vs-button
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5" align="left">
              <vs-button
                @click="saveUpdatedTreatmentsConfirmationPopUp = false"
                icon-pack="feather"
                type="border"
                color="danger"
                >Cancel</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "InvoiceDetails",
  components: {
    "v-select": vSelect,
  },
  props: {
    invoiceId: {
      type: String,
    },
  },
  data() {
    return {
      id: this.invoiceId,
      isMounted: false,
      currentPage: 1,
      totalDocs: 0,
      page: 1,
      noDataText: "Loading...",
      dataTableParams: {
        search: "",
        page: 1,
        limit: 25,
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false,
      awaitingSearch: null,
      invoiceData: {},
      treatmentData: [],
      clinicName: "",
      invoiceNumber: "",
      month: "",
      year: "",
      voidEntirePayment: "",
      voidEntirePaymentPopUp: false,
      saveSendConfirmationPopUp: false,
      saveUpdatedTreatmentsConfirmationPopUp: false,
    };
  },
  methods: {
    ...mapActions("general", [
      "fetchAdminClinicInvoiceDetails",
      "voidEntirePaymentForClinic",
      "sendUpdatedTreatmentPdf"
    ]),
    moment(date) {
      return moment(date);
    },
    async fetchInvoiceDetails(id) {
      let self = this;
      self.$vs.loading();
      const data = {
        invoiceId: id,
        param: this.dataTableParams,
      };
      this.fetchAdminClinicInvoiceDetails(data).then(async (res) => {
        let dataArr = res.data.data.docs;
        if (dataArr[0].treatment.length == 0) {
          self.treatmentData = [];
        } else {
          self.treatmentData = dataArr;
        }
        self.invoiceNumber = dataArr[0].invoiceNumber;
        self.clinicName = dataArr[0].clinicDetail.name[0];
        self.month = dataArr[0].createdAt;
        self.year = dataArr[0].year;
        self.totalDocs = res.data.data.pagination.total || 0;
        self.page = res.data.data.pagination.page;
        self.currentPage = res.data.data.pagination.page;
        self.voidEntirePayment = dataArr[0].voidEntirePayment;
        self.noDataText = "No Treatment Available";
        self.$vs.loading.close();
      });
      self.serverResponded = true;
    },
    invoicingHandler(index, value) {
      this.treatmentData[index].price = value;
    },
    saveDetails() {
      this.saveSendConfirmationPopUp = false;
      this.$emit("saveTreatmentDetail", this.treatmentData);
    },
    saveUpdatedTreatmentDetails() {
      this.saveUpdatedTreatmentsConfirmationPopUp = false;
      this.$emit("saveUpdatedTreatmentDetails", this.treatmentData);
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchInvoiceDetails(this.id);
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchInvoiceDetails(this.id);
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.$refs.table.currentx = 1;
      this.fetchInvoiceDetails(this.id);
    },
    invoiceOptionHandler() {
      this.voidEntirePaymentPopUp = true;
    },
    cancelPopUpHandler() {
      this.voidEntirePaymentPopUp = false;
    },
    async cancelPaymentHandler() {
      this.$vs.loading();
      this.voidEntirePayment = true;
      this.voidEntirePaymentPopUp = false;
      const data = {
        id: this.id,
        invoicing: false,
        voidEntirePayment: this.voidEntirePayment,
      };
      await this.voidEntirePaymentForClinic(data)
        .then((res) => {
          this.$vs.notify({
            title: "Upcoming Invoice Details",
            text: "Entire payment for cancelled successfully",
            color: "success",
          });
          // this.fetchInvoiceDetails(this.id);
        })
        .catch((err) => {
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger",
          });
        });
      this.$vs.loading.close();
      this.$router.push({ name: "UpcomingInvoiceList" });
    },
    async activatePaymentHandler() {
      this.$vs.loading();
      this.voidEntirePayment = false;
      this.voidEntirePaymentPopUp = false;
      const data = {
        id: this.id,
        invoicing: true,
        voidEntirePayment: this.voidEntirePayment,
      };
      await this.voidEntirePaymentForClinic(data)
        .then((res) => {
          this.$vs.notify({
            title: "Upcoming Invoice Details",
            text: "Entire payment for activated successfully",
            color: "success",
          });

          // this.fetchInvoiceDetails(this.id);
        })
        .catch((err) => {
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger",
          });
        });
      this.$vs.loading.close();
      this.$router.push({ name: "UpcomingInvoiceList" });
    },
    checkVoidPayment() {
      if (this.voidEntirePayment == true) {
        this.$vs.notify({
          title: "Void Payment",
          text: "You need to activate payment for clinic first.",
          color: "danger",
        });
      }
    },
    saveSendPopupHandler() {
      this.saveSendConfirmationPopUp = true;
    },
    showSaveUpdatedTreatmentsConfirmation() {
      this.saveUpdatedTreatmentsConfirmationPopUp = true;
    },
    sendUpdatedPdf() {
      this.$vs.loading()
      this.sendUpdatedTreatmentPdf(this.id)
        .then(res => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: "Success",
            text: "Updated treatment detail email sent successfully",
            color: "success",
          });
        })
        .catch(err => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: "Error",
            text: "Something went wrong while sending the updated treatment detail email",
            color: "danger",
          });
        })
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.fetchInvoiceDetails(this.id);
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchInvoiceDetails(this.id);
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  async created() {
    this.id = this.invoiceId;
    await this.fetchInvoiceDetails(this.id);
  },
};
</script>

<style scoped>
  .selected-price-icon {
    color: rgba(var(--vs-primary),1)!important; 
    height: 13px; 
    margin-left: 5px;
  }
</style>
